<template>
  <v-container grid-list-xs>
    <v-row>
      <v-col cols="12" md="4" lg="4" sm="6" v-for="(item, i) in items" :key="i">
        <v-card class="ml-2">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline">{{ item.title }}</v-card-title>
              <v-card-subtitle>{{ item.subTitle }}</v-card-subtitle>
            </div>

            <div class="d-flex align-center justify-center align-center mx-4">
              <v-divider vertical inset class="mr-4"></v-divider>
              <v-icon
                :color="item.color ? item.color : ''"
                size="30"
                v-if="item.icon"
                class="green--text mr-2"
                >{{ item.icon }}</v-icon
              >
              <h2 class="text-success flex-wrap">
                {{ item.value }}
              </h2>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import currencyMixin from "@/mixins/currencyMixin";
import campanha from "@/services/http/campanhaService";
export default {
  mixins: [currencyMixin],
  data() {
    return {
      arryCardCresimentoDobro: []
    };
  },

  watch: {
    getResultadoTotal() {
      this.arryCardCresimentoDobro.map(item => {
        this.items.push(item);
      });
    }
  },

  computed: {
    ...mapGetters({
      getApuracaoQtEmpresa: "campanha/getApuracaoQtEmpresa",
      getApuracaoQtColaboradorMeta: "campanha/getApuracaoQtColaboradorMeta",
      getResultadoTotal: "campanha/getResultadoTotal",
      getResultadoTotalEstimado: "campanha/getResultadoTotalEstimado",
      getApuracaoCrecimentoGeral: "campanha/getApuracaoCrecimentoGeral"
    }),

    ...mapState("campanha", {
      campanha: state => state.apuracao.campanha
    }),

    items() {
      return [
        {
          title: "Empresa",
          subTitle: "Participantes ",
          value: this.getApuracaoQtEmpresa.length,
          color: "primary",
          icon: "mdi-domain"
        },
        {
          title: "Custo",
          subTitle: "Estimado",
          value: currencyMixin.filters.BrazilianCurrency(
            this.campanha.valor_custo || 0
          ),
          color: "primary",
          icon: "mdi-cash"
        },
        {
          title: "Crescimento",
          subTitle: "Estimado",
          value: this.campanha.perc_crescimento || 0,
          color: "primary",
          icon: "mdi-percent"
        },
        {
          title: "Resultado",
          subTitle: "Valor pago",
          value: currencyMixin.filters.BrazilianCurrency(
            this.getResultadoTotal || 0
          ),
          color: "primary",
          icon: "mdi-cash"
        },
        {
          title: "Bateram a Metas",
          subTitle: "Participantes",
          value: this.getApuracaoQtColaboradorMeta.S || 0,

          color: "success",
          icon: "mdi-arrow-up"
        },
        {
          title: "Crescimento",
          subTitle: "Geral",
          value: this.getApuracaoCrecimentoGeral || 0,
          color: "primary",
          icon: "mdi-percent"
        },
        {
          title: "Resultado",
          subTitle: "Valor estimado",
          value: currencyMixin.filters.BrazilianCurrency(
            this.getResultadoTotalEstimado || 0
          ),
          color: "primary",
          icon: "mdi-cash"
        },
        {
          title: " Não Bateram a Meta",
          subTitle: "Participantes",
          value: this.getApuracaoQtColaboradorMeta.N || 0,
          color: "error",
          icon: "mdi-arrow-down"
        },

      ];
    }
  },

  methods: {
    ...mapActions({
      actionApuracaoPublicoAlvoCampanha:
        "campanha/actionApuracaoPublicoAlvoCampanha",
    }),

    async fetchApuracaoCalculo(idCampanha) {
      let { data } = await campanha()
        .apuracaoCalculo()
        .show({ id_campanha: idCampanha, per_page: -1 });
      let funcoes = this.groupByFuncao(data, "cod_funcao");
      this.getFuncoes(funcoes);
    },

    groupByFuncao(array, key) {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        return result;
      }, {});
    },

    getFuncoes(funcao) {
      let funcoes = Object.keys(funcao);
      funcoes.map(item => {
        this.arryCardCresimentoDobro.push({
          title: "Crescimento Dobro",
          subTitle: funcao[item][0].funcao,
          value: funcao[item][0].cresc_dobro || 0,
          color: "primary",
          icon: "mdi-percent"
        });
      });
    }
  },

  async mounted() {
    const idCampanha = this.$router.history.current.params.idCampanha;
    await this.fetchApuracaoCalculo(idCampanha);
    await this.actionApuracaoPublicoAlvoCampanha({
      id_campanha: idCampanha});
  }
};
</script>

<style scoped>
.v-card {
  height: 100%;
}

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
</style>
